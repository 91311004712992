import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer2 from "./Components/Footer2/footer2";
import Navbar from "./Components/Navbar/navbar";
import Home from "./Pages/Home/home";
import ForHer from "./Pages/ForHer/forHer";
import ForHim from "./Pages/ForHim/forHim";
import New from "./Pages/New/new";
import Subscription from "./Pages/Subscription/subscription";
import AddToCart from "./Pages/AddToCart/addToCart";
import MyCart from "./Pages/Cart/myCart";
import Profile from "./Pages/Profile/profile";
import Auth from "./Pages/Auth/auth";
import AboutUs from "./Pages/AboutUs/aboutUs";
import PageNotFound from "./Pages/PageNotFound/pageNotFound";
import AllProducts from "./Pages/Products/allProducts";
import Checkout from "./Pages/Checkout/checkout";
import { useEffect, useRef } from "react";
import Modal from "react-modal";
import ContactUs from "./Pages/ContactUs/contactUs";
import ScrollToTop from "./Components/scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isTokenValid } from "./utils/verifyToken";
import PaymentSuccess from "./Pages/Stripe/paymentSuccess";
import PaymentFailure from "./Pages/Stripe/paymentFailure";
import ForgetPassword from "./Pages/Auth/forgetPassword";
import ChangePassword from "./Pages/Auth/changePassword";

function App() {
  const packagesRef = useRef(null); // Reference for the target div

  useEffect(() => {
    // Check if the token is valid
    if (!isTokenValid(localStorage.getItem("token"))) {
      localStorage.removeItem("token");
    }
  }, []);

  const scrollToTargetDiv = () => {
    // Smooth scroll to the target div
    if (packagesRef.current) {
      packagesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  Modal.setAppElement(document.getElementById("root"));

  return (
    <div className="helvetica">
      <Navbar onScrollToTarget={scrollToTargetDiv} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/new" element={<New />} />
        <Route path="/for-her" element={<ForHer />} />
        <Route path="/for-him" element={<ForHim />} />
        <Route
          path="/subscription"
          element={<Subscription ref={packagesRef} />}
        />
        <Route path="/addToCart" element={<AddToCart />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/my-cart" element={<MyCart />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/products" element={<AllProducts />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/request-otp" element={<ForgetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />

        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer2 />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
