import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  womenData: [],
  womenProducts: [],
};

const forHerPageSlice = createSlice({
  name: "forHer",
  initialState,
  reducers: {
    getWomenDataRequest: (state, action) => {
      state.loading = true;
      state.womenData = [];
      state.error = false;
    },
    getWomenDataSuccess: (state, action) => {
      state.loading = false;
      state.womenData = action.payload;
      state.error = false;
    },
    getWomenDataFailure: (state, action) => {
      state.loading = false;
      state.womenData = [];
      state.error = action.payload;
    },

    getWomenProductsRequest: (state, action) => {
      state.loading = true;
      state.womenProducts = [];
      state.error = false;
    },
    getWomenProductsSuccess: (state, action) => {
      state.loading = false;
      state.womenProducts = action.payload;
      state.error = false;
    },
    getWomenProductsFailure: (state, action) => {
      state.loading = false;
      state.womenProducts = [];
      state.error = action.payload;
    },
  },
});

export const ForHerPageAction = forHerPageSlice.actions;
export default forHerPageSlice;
