import React, { useEffect, useState } from "react";
import Recommended from "../../Assets/Women/recommended.webp";
import InitialBg from "../../Assets/Women/initialBg.png";
import SpecialCard from "../../Components/specialsCard";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWomenData } from "../../Redux/ForHer/ForHerActions";
import Footer1 from "../../Components/Footer1/footer1";
import PerfumeCard2 from "../../Components/PerfumeCard/perfumeCard2";
import { getProducts } from "../../Redux/Products/ProductsActions";
import Loader from "../../Components/Loader/loader";

const ForHer = () => {
  const [perfumes, setPerfumes] = useState([]);
  const [homeSection, setHomeSection] = useState([]);
  const [recommendedSection, setRecommendedSection] = useState([]);
  const [specialBackgroundsSection, setSpecialBackgroundsSection] = useState(
    []
  );
  const [specialPerfumesSection, setSpecialPerfumesSection] = useState([]);

  const dispatch = useDispatch();
  const { loading, womenData } = useSelector((store) => store.forHerReducer);
  const { products } = useSelector((store) => store.productsReducer);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getWomenData());
  }, [dispatch]);

  const special_perfumes_data = [
    {
      brand: "Acqua di Parma",
      tagline: "Timeless Italian Elegance",
      name: "Colonia Essenza",
      description:
        "A refined blend of citrus, floral, and woody notes, embodying the essence of Italian sophistication and charm.",
    },
    {
      brand: "Tom Ford",
      tagline: "Luxury Redefined in Leather",
      name: "Tuscan Leather",
      description:
        "A bold and intoxicating fragrance featuring leather, saffron, and raspberry, creating a warm and sensual allure.",
    },
    {
      brand: "Tom Ford",
      tagline: "Cherry's Forbidden Desire",
      name: "Lost Cherry",
      description:
        "A rich and decadent scent with black cherry, almond, and Turkish rose, layered over a warm base of tonka bean and sandalwood.",
    },
  ];

  useEffect(() => {
    if (products.data) {
      const filteredPerfumes = products?.data?.filter(
        (perfume, index, self) =>
          perfume.category === "Female" &&
          perfume.subscriptionCategory === "None" &&
          self.findIndex((t) => t.name === perfume.name) === index
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5)
        .slice(0, 8);
      setPerfumes(randomPerfumes);
    }

    if (womenData.data) {
      setHomeSection(womenData.data.filter((data) => data.section === "home"));
      setRecommendedSection(
        womenData.data.filter((data) => data.section === "recommended")
      );
      setSpecialBackgroundsSection(
        womenData.data.filter((data) => data.section === "special_backgrounds")
      );
      setSpecialPerfumesSection(
        womenData.data.filter((data) => data.section === "special_perfumes")
      );
    }
  }, [womenData, products]);

  return (
    <div className="bg-[#313131]">
      {!loading && (
        <div className="w-full bg-[#313131] pb-10">
          {/* Heading Image with Text */}
          <div
            className="w-full h-screen bg-cover bg-right flex items-start justify-center"
            style={{
              backgroundImage: `url(${
                homeSection[0]?.content
                  ? process.env.REACT_APP_BASE_URL + homeSection[0]?.content
                  : InitialBg
              })`,
              backgroundSize: "cover",
              backgroundPosition: "80% 50%",
            }}
          >
            <div className="w-full h-full px-2 lg:px-0 lg:w-4/5 mx-auto pt-5 flex flex-col justify-start md:justify-center gap-2">
              <h1 className="text-white text-sm font-thin">Women's Colognes</h1>
              <h2 className="text-white text-4xl md:text-6xl font-thin w-full lg:w-2/3 ">
                Discover the Essence <br /> of Femininity
              </h2>
              <p className="w-1/3 hidden md:block text-white font-thin opacity-80 text-sm">
                Embrace the art of allure with our exquisite collection of
                fragrances. Each scent captures grace, elegance, and
                individuality, crafted to enhance your unique charm. From
                timeless classics to contemporary blends, find the perfect
                fragrance to accompany every occasion and express your true
                self. Let your scent tell your story.
              </p>
              <div className="flex flex-row gap-2 mt-5">
                <Link to={"/products"} state={{ gender: "Female" }}>
                  <div className="bg-white text-black p-2 px-3 md:p-3 md:px-6 font-thin text-sm cursor-pointer">
                    Explore our collection
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Highly Recommended Image + Title */}
          <div className="py-9">
            <div className="w-full text-center">
              <h1 className="text-white text-3xl md:text-5xl">
                Highly recommended
              </h1>
            </div>

            <div className="w-full md:w-4/5 mx-auto mt-10 relative">
              <img src={Recommended} alt="" className="w-full h-full" />

              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  recommendedSection[0]?.content
                }
                alt="Perfume"
                class="perfume_women"
              />

              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  recommendedSection[1]?.content
                }
                alt="Perfume"
                class="perfume_women_2"
              />
            </div>
          </div>

          {/* Specials */}
          <div className="py-9">
            <div className="w-full text-center">
              <h1 className=" text-white text-3xl md:text-5xl">Specials</h1>
            </div>

            <div className="w-4/5 m-auto mt-10 grid grid-cols-1 custom-grid gap-2">
              {specialPerfumesSection.map((element, index) => (
                <div key={index} className="w-full flex">
                  <SpecialCard
                    index={index + 4}
                    perfume={element}
                    backgroundImage={specialBackgroundsSection[index]?.content}
                    special_perfumes_data={special_perfumes_data[index]}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product List */}
          <div className="py-9">
            <div className="w-full text-center text-white">
              <h1 className=" text-3xl md:text-5xl">Product List</h1>
            </div>
          </div>

          <div className="w-11/12 mx-auto py-10">
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4  gap-4 gap-y-10">
              {perfumes?.map((element, index) => (
                <PerfumeCard2 perfume={element} key={index} />
              ))}
            </div>
          </div>

          <div className="w-full flex flex-row items-center justify-center mt-10">
            <Link to={"/products"} state={{ gender: "Female" }}>
              <div className="p-2 bg-white text-black cursor-pointer">
                Explore our collection
              </div>
            </Link>
          </div>
        </div>
      )}

      {loading && (
        <div className="w-full h-screen flex items-center justify-center">
          <Loader />
        </div>
      )}

      <Footer1 />
    </div>
  );
};

export default ForHer;
