import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

import Footer1 from "../../Components/Footer1/footer1";
import SubscriptionModal from "../../Components/subscriptionModal";
import PackageCard from "../../Components/packageCard";
import { useDispatch, useSelector } from "react-redux";
import { getHomePageData } from "../../Redux/Home/HomeActions";

const Home = () => {
  const dispatch = useDispatch();
  const { homePageData, loading } = useSelector((store) => store.homeReducer);
  const [welcomeSection, setWelcomeSection] = useState([]);
  const [section2, setSection2] = useState([]);
  const [heroSection, setHeroSection] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    dispatch(getHomePageData());
  }, [dispatch]);

  useEffect(() => {
    if (homePageData.data) {
      setWelcomeSection(
        homePageData.data.filter((data) => data.section === "welcome")
      );
      setSection2(
        homePageData.data.filter((data) => data.section === "section2")
      );
      setHeroSection(
        homePageData.data.filter((data) => data.section === "hero")
      );
      setCtaSection(homePageData.data.filter((data) => data.section === "cta"));
    }
  }, [homePageData]);

  useEffect(() => {
    console.log({ Loading: loading });
  }, [loading]);

  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const openModal = () => {
    setSubscriptionModalOpen(true);
  };
  const closeModal = () => {
    setSubscriptionModalOpen(false);
  };

  return (
    <div className="w-full text-white">
      <SubscriptionModal
        isOpenModal={isSubscriptionModalOpen}
        closeModal={closeModal}
        subscription={subscription}
      />

      <div className="w-full h-[90vh]">
        {welcomeSection.length > 0 && (
          <Swiper
            initialSlide={1}
            modules={[Pagination, Autoplay]} // Import modules explicitly
            spaceBetween={30}
            pagination={{ clickable: true, type: "fraction" }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="bg-[#313131] h-full"
          >
            {welcomeSection.map((element, index) => (
              <SwiperSlide key={index}>
                <div
                  className="h-full bg-cover bg-center pt-20 pb-10"
                  style={{
                    backgroundImage: `url(${
                      process.env.REACT_APP_BASE_URL + element.content
                    }?quality=300)`,
                    backgroundPosition:
                      window.innerWidth < 670 ? "center right" : "",
                  }}
                >
                  <div className=" flex items-start justify-center fade-in">
                    <div className="w-full px-2 lg:px-0 lg:w-4/5 mx-auto pt-5 flex flex-col gap-2">
                      <h1 className="text-sm font-thin">
                        {
                          heroSection.find(
                            (element) => element.subsection === "h3Title"
                          ).content
                        }
                      </h1>
                      <h2 className=" text-6xl font-thin w-full lg:w-2/3 ">
                        {
                          heroSection.find(
                            (element) => element.subsection === "h1Title"
                          ).content
                        }
                      </h2>
                      <p className="w-1/3 hidden md:block font-thin opacity-80 text-sm">
                        {
                          heroSection.find(
                            (element) => element.subsection === "text"
                          ).content
                        }
                      </p>
                      <div className="flex flex-row gap-2 mt-5">
                        <Link to={"/products"}>
                          <div className="bg-white text-black p-2 px-2 font-thin text-sm cursor-pointer">
                            Explore our collection
                          </div>
                        </Link>

                        <Link to={"/about-us"}>
                          <div className="border p-2 px-2 font-thin text-sm cursor-pointer">
                            Who we are
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="w-full body py-10">
        <div className="w-4/5 m-auto flex flex-col items-center justify-center gap-7 ">
          <div className="text-white text-center text-5xl body_title">
            Find what you desire quickly
          </div>

          <div className="hidden md:flex flex-row gap-2 ">
            {section2.map((element, index) => (
              <div
                className="flex-1 max-h-96 max-w-72 flex justify-center"
                key={index}
              >
                <img
                  src={process.env.REACT_APP_BASE_URL + element.content}
                  className="w-full h-full"
                  alt=""
                />
              </div>
            ))}
          </div>

          <div className="w-4/5 block md:hidden ">
            <Swiper
              modules={[Pagination]}
              spaceBetween={0}
              pagination={{ clickable: true }}
              loop={true}
              className="w-full"
            >
              {section2.map((element, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={process.env.REACT_APP_BASE_URL + element.content}
                    className="w-full"
                    alt=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <Link to={"/products"} className="w-2/3 md:w-1/4 ">
            <div className=" border p-2 font-thin cursor-pointer text-center bg-white hover:bg-slate-200 transition duration-300 text-black">
              <span>Start shopping</span>
            </div>
          </Link>
        </div>

        <div className="w-4/5 m-auto mt-10 text-white flex flex-row gap-3 justify-center items-center">
          <PackageCard
            openModal={openModal}
            setSubscription={setSubscription}
            subscription={subscription}
          />
        </div>

        <div className=" w-full flex items-center justify-center mt-10">
          <Link to={"/subscription"}>
            <div className="text-black bg-white hover:bg-slate-200 transition duration-300 px-5 py-2  cursor-pointer">
              <span>Learn more</span>
            </div>
          </Link>
        </div>
      </div>

      <Footer1 cta={ctaSection} />
    </div>
  );
};

export default Home;
