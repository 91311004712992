import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/loader";
import PerfumeCard2 from "../../Components/PerfumeCard/perfumeCard2";

const AllProducts = () => {
  const [perfumes, setPerfumes] = useState([]);

  const location = useLocation();
  const { gender } = location.state || {};

  const dispatch = useDispatch();
  const { loading, products } = useSelector((store) => store.productsReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const productsPerPage = 9;

  const [searchFilter, setSearchFilter] = useState("");
  const [selectedGender, setSelectedGender] = useState(gender ? gender : "All");

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch, selectedGender]);

  useEffect(() => {
    if (products.data) {
      setPerfumes(products.data);
    }
  }, [products]);

  const handleCheckboxChange = (value) => {
    setSearchFilter("");
    setSelectedGender(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const filteredPerfumes = products?.data
      ?.filter((perfume, index, self) => {
        return (
          perfume.subscriptionCategory === "None" &&
          index === self.findIndex((p) => p.name === perfume.name) &&
          perfume.name.toLowerCase().includes(searchFilter.toLowerCase()) &&
          (selectedGender === "All" || perfume.category === selectedGender)
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    console.log(filteredPerfumes);

    const calculatedTotalPages = Math.ceil(
      filteredPerfumes?.length / productsPerPage
    );
    setTotalPages(calculatedTotalPages);
    const startIndex = (currentPage - 1) * productsPerPage;
    const paginatedProducts = filteredPerfumes?.slice(
      startIndex,
      startIndex + productsPerPage
    );

    setPerfumes(paginatedProducts);
  }, [searchFilter, , products, currentPage]);

  return (
    <div className="relative bg-[rgb(49,49,49)] ">
      <div className=" py-10">
        {/* Search */}
        <div className="w-11/12 mx-auto mt-5">
          <div className="bg-[#454545] p-3 lg:p-11">
            <form id="search" className="w-full flex flex-col gap-3">
              <label className="text-white text-2xl ">Search</label>
              <div className="flex flex-row gap-2 items-center bg-[#d9d9d9] px-3 ">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="mdi:magnify">
                    <path
                      id="Vector"
                      d="M9.5 3.33203C11.2239 3.33203 12.8772 4.01685 14.0962 5.23584C15.3152 6.45482 16 8.10812 16 9.83203C16 11.442 15.41 12.922 14.44 14.062L14.71 14.332H15.5L20.5 19.332L19 20.832L14 15.832V15.042L13.73 14.772C12.5504 15.7785 11.0506 16.3316 9.5 16.332C7.77609 16.332 6.12279 15.6472 4.90381 14.4282C3.68482 13.2092 3 11.5559 3 9.83203C3 8.10812 3.68482 6.45482 4.90381 5.23584C6.12279 4.01685 7.77609 3.33203 9.5 3.33203ZM9.5 5.33203C7 5.33203 5 7.33203 5 9.83203C5 12.332 7 14.332 9.5 14.332C12 14.332 14 12.332 14 9.83203C14 7.33203 12 5.33203 9.5 5.33203Z"
                      fill="black"
                      style={{ fill: "black", fillOpacity: 1 }}
                    />
                  </g>
                </svg>
                <input
                  type="text"
                  placeholder="Search here"
                  className="w-full p-1 bg-[#d9d9d9]"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchFilter(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="w-11/12 mx-auto flex flex-col lg:flex-row mt-4 text-white">
          {/* DESKTOP FILTER PART */}
          <div className="w-1/4 hidden lg:block">
            <div className="flex flex-col gap-1 py-5 ">
              <h1 className="text-2xl">Choose Type :</h1>
              <div className="flex flex-row gap-2  w-3/4">
                <div
                  className={`flex-1 border p-2 px-2 text-center ${
                    selectedGender === "Male"
                      ? "bg-white text-black"
                      : "bg-transparent text-white "
                  } cursor-pointer`}
                  onClick={() => handleCheckboxChange("Male")}
                >
                  <span>Male</span>
                </div>

                <div
                  className={`flex-1 border p-2 px-2 text-center ${
                    selectedGender === "Female"
                      ? "bg-white text-black"
                      : "bg-transparent text-white"
                  } cursor-pointer`}
                  onClick={() => handleCheckboxChange("Female")}
                >
                  <span>Female</span>
                </div>

                <div
                  className={`flex-1 border p-2 px-2 text-center ${
                    selectedGender === "All"
                      ? "bg-white text-black"
                      : "bg-transparent text-white"
                  } cursor-pointer`}
                  onClick={() => handleCheckboxChange("All")}
                >
                  <span>All</span>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-3/4 flex flex-col lg:hidden gap-1 py-2 pb-4 ">
            <h1 className="text-2xl">Choose Type :</h1>
            <div className="flex flex-row gap-2  ">
              <div
                className={`flex-1 border p-2 px-2 text-center ${
                  selectedGender === "Male"
                    ? "bg-white text-black"
                    : "bg-transparent text-white "
                } cursor-pointer`}
                onClick={() => setSelectedGender("Male")}
              >
                <span>Male</span>
              </div>

              <div
                className={`flex-1 border p-2 px-2 text-center ${
                  selectedGender === "Female"
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                } cursor-pointer`}
                onClick={() => setSelectedGender("Female")}
              >
                <span>Female</span>
              </div>

              <div
                className={`flex-1 border p-2 px-2 text-center ${
                  selectedGender === "All"
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                } cursor-pointer`}
                onClick={() => setSelectedGender("All")}
              >
                <span>All</span>
              </div>
            </div>
          </div>

          {!loading && (
            <div className="w-full lg:w-3/4 grid grid-cols-2 md:grid-cols-3 gap-3">
              {perfumes &&
                perfumes.map((element, index) => (
                  <PerfumeCard2 perfume={element} key={index} />
                ))}
            </div>
          )}

          {loading && (
            <div className="w-full lg:w-3/4 grid grid-cols-2 md:grid-cols-3 gap-3">
              {Array.from({ length: 9 }).map((_, index) => (
                <div key={index} className="skeleton-card">
                  <div className="skeleton-img"></div>
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text short"></div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-center items-center gap-4 my-8">
          {/* First Button */}
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className={`w-8 h-8 p-5 rounded-full flex items-center justify-center border text-sm text-white
      ${
        currentPage === 1
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-gray-700 hover:text-white"
      }`}
          >
            First
          </button>

          {/* Previous Button */}
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`w-8 h-8 p-5 rounded-full flex items-center justify-center border text-sm text-white
      ${
        currentPage === 1
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-gray-700 hover:text-white"
      }`}
          >
            Prev
          </button>

          {/* Next Button */}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`w-8 h-8 p-5 rounded-full flex items-center justify-center border text-sm text-white
      ${
        currentPage === totalPages
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-gray-700 hover:text-white"
      }`}
          >
            Next
          </button>

          {/* Last Button */}
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className={`w-8 h-8 p-5 rounded-full flex items-center justify-center border text-sm text-white
      ${
        currentPage === totalPages
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-gray-700 hover:text-white"
      }`}
          >
            Last
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
