import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileData,
  getUserOrders,
  getUserSubscription,
} from "../../Redux/Profile/ProfileActions";
import EditUserInfoModal from "./Modals/editUserInfoModal";
import RemoveSubscriptionModal from "./Modals/removeSubscriptionModal";
import Arrow from "../../Assets/Profile/arrow.png";
import { Link } from "react-router-dom";
import EditSubscriptionModal from "./Modals/editSubscription";
import Loader from "../../Components/Loader/loader";

const Profile = () => {
  const [selectedOrders, setSelectedOrders] = useState("NotPending");

  const dispatch = useDispatch();
  const { loading, profileData, userSubscriptions, userOrders } = useSelector(
    (store) => store.profileReducer
  );

  const [userData, setUserData] = useState({});
  const [subscriptions, setSubscriptions] = useState({});
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/auth";
    }

    dispatch(getProfileData());
    dispatch(getUserSubscription());
    dispatch(getUserOrders());
  }, [dispatch]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (profileData) {
      setUserData(profileData);
      setOrders(userOrders.filter((order) => order.status !== "Pending"));
      setSubscriptions(userSubscriptions || {});
    }
  }, [profileData, userSubscriptions, userOrders]);

  useEffect(() => {
    if (selectedOrders === "Pending") {
      setOrders(userOrders.filter((order) => order.status === "Pending"));
    } else {
      setOrders(userOrders.filter((order) => order.status !== "Pending"));
    }
  }, [selectedOrders, userOrders]);

  const [isEditProfileModalOpen, setOpenEditProfileModal] = useState(false);
  const [isRemoveSubsModalOpen, setRemoveSubsModal] = useState(false);
  const [isEditSubscriptionModalOpen, setIsEditSubscriptionModalOpen] =
    useState(false);

  const openEditSubscriptionModal = () => {
    setIsEditSubscriptionModalOpen(true);
  };

  const closeEditSubscriptionModal = () => {
    setIsEditSubscriptionModalOpen(false);
  };

  const openModal = () => {
    setOpenEditProfileModal(true);
  };

  const closeModal = () => {
    setOpenEditProfileModal(false);
  };

  const openRemoveSubscriptionModal = () => {
    setRemoveSubsModal(true);
  };

  const closeRemoveSubscriptionModal = () => {
    setRemoveSubsModal(false);
  };

  return (
    <div className="w-full bg-[#313131]">
      <RemoveSubscriptionModal
        isOpen={isRemoveSubsModalOpen}
        onClose={closeRemoveSubscriptionModal}
        subscription={subscriptions}
      ></RemoveSubscriptionModal>
      <EditUserInfoModal
        isOpen={isEditProfileModalOpen}
        closeModal={closeModal}
        profileData={userData}
      ></EditUserInfoModal>
      <EditSubscriptionModal
        isOpen={isEditSubscriptionModalOpen}
        onClose={closeEditSubscriptionModal}
        subscription={subscriptions}
      ></EditSubscriptionModal>
      {!loading && (
        <div className="w-11/12 md:w-4/5 mx-auto py-5">
          <div
            className="bg-[#1D1D1D] rounded-md flex flex-col lg:flex-row gap-3 p-5 "
            onClick={() => {
              openModal();
            }}
          >
            <div className="lg:w-1/3 flex flex-col justify-center items-center">
              <div className="w-48 h-48 rounded-full overflow-hidden flex justify-center items-center bg-gray-200">
                <img
                  src={process.env.REACT_APP_BASE_URL + userData?.image}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-white mt-2">{userData?.name}</span>
            </div>

            <div className="lg:w-2/3 flex flex-col gap-2 text-white">
              <div className="flex flex-col lg:flex-row gap-2 ">
                <div className="flex-1 flex flex-col">
                  <h1>Email number</h1>
                  <div className="bg-white rounded-md py-1 text-black ">
                    <span className="pl-2 opacity-60 block overflow-hidden text-ellipsis whitespace-nowrap">
                      {userData?.email}
                    </span>
                  </div>
                </div>

                <div className="flex-1 flex flex-col">
                  <h1>Phone number</h1>
                  <div className="bg-white rounded-md py-1  text-black ">
                    <span className="pl-2 opacity-60">
                      {userData?.phone_number !== null
                        ? userData?.phone_number
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-2">
                <div className="flex-1 flex flex-col">
                  <h1>Date of birth</h1>
                  <div className="bg-white rounded-md py-1  text-black ">
                    <span className="pl-2 opacity-60">
                      {formatDate(userData?.date_of_birth)}
                    </span>
                  </div>
                </div>

                <div className="flex-1 flex flex-col">
                  <h1>Country</h1>
                  <div className="bg-white rounded-md py-1  text-black ">
                    <span className="pl-2 opacity-60">
                      {userData?.country !== "null" ? userData?.country : ""}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <div className="flex-1 flex flex-col">
                  <h1>Profession</h1>
                  <div className="bg-white rounded-md py-1  text-black ">
                    <span className="pl-2 opacity-60">
                      {userData?.profession}
                    </span>
                  </div>
                </div>

                <div className="flex-1 flex flex-col">
                  <h1>Password</h1>
                  <div className="bg-white rounded-md py-1  text-black ">
                    <span className="pl-2 opacity-60">*****************</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#1D1D1D] rounded-md flex flex-col gap-3 p-5  mt-5 text-white">
            <h1>My Subscriptions</h1>

            {subscriptions && subscriptions.package ? (
              <div
                className="border rounded-md p-3"
                onClick={() => {
                  openEditSubscriptionModal();
                }}
              >
                <div className="bg-[#383838] p-2 rounded-md">
                  <h1>
                    {subscriptions?.package?.name} |{" "}
                    {subscriptions?.package?.price} $ /Month
                  </h1>
                </div>

                <div className="w-full flex flex-col lg:flex-row justify-between pt-2">
                  <span>Monthly</span>
                  <span
                    className="text-red-700 text-sm cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      openRemoveSubscriptionModal();
                    }}
                  >
                    Cancel Subscription
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-center text-gray-400 mt-3">
                <p>No subscriptions found</p>
              </div>
            )}
          </div>

          <div className="bg-[#1D1D1D] rounded-md flex flex-col gap-3 p-5  mt-5 text-white">
            <h1>My Orders</h1>

            <div className="flex flex-row text-center">
              <h1
                className={`flex-1 border-b-2 cursor-pointer ${
                  selectedOrders === "Pending" ? "" : "opacity-40"
                }`}
                onClick={() => setSelectedOrders("Pending")}
              >
                Running Orders
              </h1>
              <h1
                className={`flex-1 border-b-2 cursor-pointer ${
                  selectedOrders === "NotPending" ? "" : "opacity-40"
                }`}
                onClick={() => setSelectedOrders("NotPending")}
              >
                Previous Orders
              </h1>
            </div>

            <div className="p-4 border">
              {orders.length > 0 ? (
                orders.map((order, orderIndex) => (
                  <div key={orderIndex} className="p-4 border mb-4">
                    <div className="flex flex-row justify-between mb-2">
                      <h1 className="opacity-60">Order {orderIndex + 1}</h1>
                      <h1 className="opacity-60">{order.status}</h1>
                    </div>

                    {order.products.map((product, productIndex) => {
                      const total =
                        product.product_price * product.quantity_ordered;
                      return (
                        <div key={productIndex} className="mb-2">
                          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                            <div className="flex flex-col md:flex-row items-center gap-4 p-2">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${product.product_image}`}
                                alt=""
                                width={80}
                              />
                              <div className="flex flex-col gap-2">
                                <span>{product.product_name}</span>
                                <span>Qty: {product.quantity_ordered}</span>
                              </div>
                            </div>

                            <div>
                              <span>${total.toFixed(2)}</span>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-400 mt-3">
                  <p>No orders found</p>
                </div>
              )}
            </div>

            <Link to={"/contact-us"}>
              <div className="p-2 border flex flex-row justify-between ">
                <h1>Ask a question (contact us)</h1>
                <img src={Arrow} width={30} alt="" />
              </div>
            </Link>
          </div>
        </div>
      )}

      {loading && (
        <div className="w-full h-screen flex justify-center items-center">
          \
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Profile;
