import { useNavigate } from "react-router-dom";

const SpecialCard = ({
  perfume,
  backgroundImage,
  special_perfumes_data,
  index,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col ${
        index == 1
          ? "specials_card_1"
          : index === 2
          ? "specials_card_2"
          : index === 3
          ? "specials_card_3"
          : index === 4
          ? "specials_card_4"
          : index === 5
          ? "specials_card_5"
          : "specials_card_6"
      }`}
    >
      <div className="w-full relative ">
        <div className="absolute image-special transform -translate-x-1/2 w-60 h-60 flex items-center justify-center">
          <img
            src={process.env.REACT_APP_BASE_URL + perfume?.content}
            alt=""
            className="-rotate-6 w-full h-full object-contain"
          />
        </div>
        <div>
          <img
            src={process.env.REACT_APP_BASE_URL + backgroundImage}
            alt=""
            className="mx-auto w-full"
          />
        </div>
      </div>

      <div className=" pt-36 px-5 flex text-white pb-9 h-full">
        <div className="flex flex-col items-center gap-5 ">
          <h1 className=" text-3xl">{special_perfumes_data.brand}</h1>
          <h2 className="text-center text-sm">
            {special_perfumes_data.tagline}
          </h2>

          <hr className="horizontal transparent w-1/2 mx-auto opacity-50"></hr>

          <h3 className=" font-bold">{special_perfumes_data.name}</h3>
          <p className="text-center text-sm font-thin flex-grow">
            {special_perfumes_data.description}
          </p>

          <div
            className={`border text-sm font-thin px-3 p-1 cursor-pointer flex
              ${
                index == 1
                  ? "hover:bg-[#6d411a] "
                  : index === 2
                  ? "hover:bg-[#383838] "
                  : index === 3
                  ? "hover:bg-[#c18521] "
                  : index === 4
                  ? "hover:bg-[#6e4f32] "
                  : index === 5
                  ? "hover:bg-[#a91da1] "
                  : "hover:bg-[#e2a99c] "
              }
            `}
            onClick={() => navigate("/products")}
          >
            <span>Learn more</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialCard;
