import React from "react";
import { useNavigate } from "react-router-dom";
import { Img } from "react-image";
import Loader from "../../Components/Loader/loader";
import "./style.css";
const PerfumeCardSwipper = ({ perfume }) => {
  const navigate = useNavigate();

  const navigateToAddToCart = () => {
    navigate("/addToCart", { state: { perfume } });
  };

  return (
    <div
      className="flex flex-col justify-between items-stretch gap-3 h-full cursor-pointer"
      onClick={navigateToAddToCart}
    >
      {/* Image Section */}
      <div className="bg-[#454545]  flex items-center justify-center hover:scale-105 transition-transform duration-300">
        <div className="flex items-center justify-center w-full">
          <div className="h-56 flex justify-center items-center w-full">
            {/* <div class="skeleton-img h-full"></div> */}
            <Img
              src={process.env.REACT_APP_BASE_URL + perfume?.image}
              alt=""
              width={200}
              className=" p-4"
              loader={<div class="skeleton-img h-full"></div>}
            />
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex-grow flex flex-col gap-2 text-white">
        <h1 className={`text-lg h-20  opacity-80 line-clamp-2`}>
          {perfume?.name}
        </h1>
        <div className="border text-white text-center p-1 px-5 cursor-pointer">
          <span className="text-sm">Learn More</span>
        </div>{" "}
      </div>
    </div>
  );
};

export default PerfumeCardSwipper;
