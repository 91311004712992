import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestOTPCode } from "../../Redux/Auth/AuthActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthPageAction } from "../../Redux/Auth/AuthReducer";

const ForgetPassword = () => {
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestOtp } = useSelector((store) => store.authReducer);

  useEffect(() => {
    if (Object.keys(requestOtp).length > 0) {
      navigate("/change-password", { state: { email } });
      dispatch(AuthPageAction.resetRequestOtp());
      setEmail("");
    }
  }, [requestOtp]);

  const handleRequestCode = (e) => {
    e.preventDefault();
    if (!email || !email.trim()) {
      toast.error("Please enter a valid email");
      return;
    }
    dispatch(requestOTPCode(email));
  };

  return (
    <div className="w-full bg-[#313131] text-white py-20 flex justify-center">
      <div className="w-4/5 md:w-2/3 lg:w-1/3 flex flex-col gap-6">
        <div className="text-center">
          <h1 className="text-2xl md:text-4xl ">Forgot Password?</h1>
          <p className="opacity-60">
            Enter your email to request a password reset code.
          </p>
        </div>

        <form
          onSubmit={handleRequestCode}
          className="flex flex-col gap-4 items-center"
        >
          <div className="w-full flex flex-col gap-2">
            <label className="opacity-60">Email</label>
            <input
              type="email"
              value={email}
              className="w-full p-2 rounded-md text-black outline-none opacity-60"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            onClick={handleRequestCode}
            type="submit"
            className="bg-black text-white p-2 px-6 rounded-md text-center cursor-pointer"
          >
            Request Code
          </button>
        </form>

        {/* <div className="text-center">
            <p>
              A password reset code has been sent to <strong>{email}</strong>.
              Please check your inbox.
            </p>
            <button className="mt-4 bg-black text-white p-2 px-6 rounded-md cursor-pointer">
              Back to Login
            </button>
          </div>
         */}
      </div>
    </div>
  );
};

export default ForgetPassword;
