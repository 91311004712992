import React, { useEffect, useState } from "react";
import "./style.css";
import PerfumeCard from "../../Components/PerfumeCard/perfumeCard";
import Footer1 from "../../Components/Footer1/footer1";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { getNewData } from "../../Redux/New/NewActions";
import Loader from "../../Components/Loader/loader";
import PerfumeCard2 from "../../Components/PerfumeCard/perfumeCard2";
const New = () => {
  const [perfumes, setPerfumes] = useState([]);
  const [newData, setNewData] = useState([]);
  const dispatch = useDispatch();
  const { products } = useSelector((store) => store.productsReducer);
  const { loading, new_data } = useSelector((store) => store.newReducer);
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getNewData());
  }, [dispatch]);

  useEffect(() => {
    if (products.data) {
      setPerfumes(
        products.data
          .filter((product) => product.perfume_size === "5ml")
          .slice(0, 8)
      );
    }
    if (new_data.data) {
      setNewData(new_data.data);
    }
  }, [products, new_data]);

  return (
    <div className="bg-[#313131]">
      {!loading && (
        <div className="w-full bg-[#313131] py-10">
          <div className="w-4/5 m-auto  pb-6">
            <h1 className="text-white text-2xl md:text-5xl ">
              Our New Fragrance Samples
            </h1>
          </div>

          <div className="w-full ">
            <img
              src={process.env.REACT_APP_BASE_URL + newData[0]?.content}
              className="w-full"
              alt="Image_Heading"
              width={300}
            />
          </div>

          <div className="w-4/5 mx-auto mt-10 pb-5">
            <h2 className="text-center text-white text-2xl md:block hidden">
              Explore the new perfumes we recently added
            </h2>
          </div>

          <div className="w-11/12 mx-auto md:mt-10">
            <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 gap-y-10">
              {perfumes.map((element, index) => (
                <PerfumeCard2 perfume={element} key={index} />
              ))}
            </div>
          </div>

          <Link to={"/products"}>
            <div className="w-full flex flex-row items-center justify-center mt-10">
              <div className="p-2 bg-white text-black cursor-pointer">
                Explore our collection
              </div>
            </div>
          </Link>
        </div>
      )}

      {loading && (
        <div className="w-full h-screen flex justify-center items-center">
          <Loader />
        </div>
      )}

      <Footer1 />
    </div>
  );
};

export default New;
