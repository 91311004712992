import React, { useEffect, useState } from "react";
import "./style.css";
import Footer1 from "../../Components/Footer1/footer1";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../../Redux/Cart/CartActions";
import { isTokenValid } from "../../utils/verifyToken";

const MyCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((store) => store.cartReducer);

  useEffect(() => {
    if (!isTokenValid()) {
      navigate("/auth");
    }
  }, [navigate]);

  const [total, setTotal] = useState();
  useEffect(() => {
    if (cart) {
      let total = 0;
      cart.forEach((element) => {
        const price = element.perfume.price;

        total += parseFloat(price) * element.quantity;
      });
      setTotal(total.toFixed(2));
    }
  }, [cart]);

  const handleIncreaseQuantity = (e, element) => {
    e.preventDefault();
    dispatch(increaseQuantity(element));
  };

  const handleDecreaseQuantity = (e, element) => {
    e.preventDefault();
    dispatch(decreaseQuantity(element));
  };

  const handleDelete = (e, element) => {
    e.preventDefault();
    dispatch(removeFromCart(element));
  };

  return (
    <>
      <div className="w-full bg-[#313131] text-white  py-20">
        <div className="w-4/5 mx-auto">
          <h1 className="mb-10 text-5xl ">My Cart</h1>

          {/* Display message if cart is empty */}
          {cart.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-20">
              <h2 className="text-2xl mb-4">Your Cart is Empty</h2>
              <p className="text-lg text-gray-400 mb-8">
                Looks like you haven’t added any items to your cart yet.
              </p>
              <Link to="/products">
                <div className="p-3 px-6 bg-blue-600 hover:bg-blue-700 cursor-pointer rounded text-white">
                  Browse Products
                </div>
              </Link>
            </div>
          ) : (
            <>
              <div className="md:flex flex-row hidden ">
                <h1 className="flex-1">Product</h1>
                <h1 className="flex-1 text-center">Quantity</h1>
                <h1 className="flex-1 text-end pr-10">Pricing</h1>
              </div>

              <div className="flex flex-col gap-4 pt-5">
                {cart.map((element, index) => (
                  <div key={index}>
                    <div className="flex md:flex-row flex-col gap-y-5">
                      <div className="flex-1 flex flex-row gap-2 items-center">
                        <div className="border p-4">
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              element.perfume.image
                            }
                            alt=""
                            width={60}
                          />
                        </div>
                        <span>
                          {element.perfume.name} -{" "}
                          {element.perfume.perfume_size}
                        </span>
                      </div>

                      <div className="flex-1 flex md:justify-center items-center flex-row gap-4">
                        QTY:
                        <div className="flex flex-row gap-2 p-1 px-2 w-28 border justify-between">
                          <span
                            onClick={(e) => {
                              handleDecreaseQuantity(e, element);
                            }}
                            className="cursor-pointer"
                          >
                            -
                          </span>
                          <span>{element.quantity}</span>
                          <span
                            onClick={(e) => {
                              handleIncreaseQuantity(e, element);
                            }}
                            className="cursor-pointer"
                          >
                            +
                          </span>
                        </div>
                      </div>

                      <div className="flex-1 flex flex-row items-center md:justify-end gap-3">
                        <span>${element.perfume.price * element.quantity}</span>
                        <div
                          className="border p-1 cursor-pointer"
                          onClick={(e) => {
                            handleDelete(e, element);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                          >
                            <path
                              d="M7.5835 22.75C6.98766 22.75 6.47777 22.538 6.05383 22.1141C5.62988 21.6901 5.41755 21.1799 5.41683 20.5833V6.5H4.3335V4.33333H9.75016V3.25H16.2502V4.33333H21.6668V6.5H20.5835V20.5833C20.5835 21.1792 20.3715 21.6894 19.9476 22.1141C19.5236 22.5388 19.0134 22.7507 18.4168 22.75H7.5835ZM18.4168 6.5H7.5835V20.5833H18.4168V6.5ZM9.75016 18.4167H11.9168V8.66667H9.75016V18.4167ZM14.0835 18.4167H16.2502V8.66667H14.0835V18.4167Z"
                              fill="white"
                              style={{ fill: "white", fillOpacity: 1 }}
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2" />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {cart.length > 0 && (
          <div className="w-4/5 mx-auto flex flex-col justify-end items-end mt-2 gap-3">
            <h1>Total</h1>
            <div className="flex md:flex-row flex-col gap-2 items-end">
              <span>Tax included. Shipping calculated at checkout </span>
              <span className="text-3xl">${total}</span>
            </div>

            <Link to={"/checkout"}>
              <div className="p-1 px-5 border cursor-pointer">
                <span>Checkout</span>
              </div>
            </Link>
          </div>
        )}
      </div>
      <Footer1 />
    </>
  );
};

export default MyCart;
