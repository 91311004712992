import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Img } from "react-image";
import { motion } from "framer-motion";
import Loader from "../Loader/loader";
import "./style.css";
import Modal from "react-modal";
import { customStyles } from "../../Shared/customStyles";

import Close from "../../Assets/Subscription/close.png";
import { isTokenValid } from "../../utils/verifyToken";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../Redux/Cart/CartActions";
import { toast } from "react-toastify";
import { getProductByName } from "../../Redux/Products/ProductsActions";
import axios from "axios";

const QuickView = ({ perfume: initialPerfume, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [sizeChosen, setSizeChosen] = useState("5ml");

  const navigate = useNavigate();
  const [perfume, setPerfume] = useState(initialPerfume);
  useEffect(() => {
    dispatch(getProductByName(initialPerfume.name));
  }, [dispatch, initialPerfume]);
  const { productByName } = useSelector((store) => store.productsReducer);

  useEffect(() => {
    if (productByName?.data) {
      setPerfume(
        productByName.data.filter((p) => p.perfume_size === sizeChosen)
      );
    }
  }, [productByName, sizeChosen]);

  const handleAddToCart = () => {
    if (!isTokenValid()) {
      navigate("/auth");
      return;
    }
    dispatch(addToCart(perfume[0], quantity, sizeChosen));
    toast.success("Perfume added to cart");
  };
  return (
    <div>
      <div className="bg-[#454545] h-auto p-6 ">
        {/* Close Button */}

        <img
          src={Close}
          width={30}
          alt=""
          onClick={onClose}
          className="cursor-pointer"
        />

        {/* Product Details */}
        <div className="flex flex-col gap-y-5 md:flex-row gap-4 items-center">
          {/* Perfume Image */}
          <div className="w-full md:w-1/3 flex items-center justify-center ">
            <Img
              src={process.env.REACT_APP_BASE_URL + perfume[0]?.image}
              alt={perfume[0]?.name}
              className="max-h-[500px]"
              loader={<Loader />}
            />
          </div>

          <div className="md:w-2/3 flex flex-col gap-y-5 ">
            {/* Perfume Info */}
            <div className="text-start">
              <h1 className="text-2xl font-bold">{perfume[0]?.name}</h1>
              <h3 className="text-lg  mt-2">${perfume[0]?.price}</h3>
              <p className="text-sm  mt-4">{perfume[0]?.description}</p>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-center">
              <h1 className="text-xl">Size : </h1>
              <div className="flex flex-row gap-2 items-center">
                <div
                  onClick={() => setSizeChosen("5ml")}
                  className={`border px-6 py-2 cursor-pointer ${
                    sizeChosen === "5ml" && "bg-[#B99545]"
                  }`}
                >
                  <span>5ml</span>
                </div>

                <div
                  onClick={() => setSizeChosen("10ml")}
                  className={`border px-6 py-2 cursor-pointer ${
                    sizeChosen === "10ml" && "bg-[#B99545]"
                  }`}
                >
                  <span>10ml</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-center">
              <span>QTY:</span>
              <div className="flex flex-row gap-2 p-1 px-2 w-28 border justify-between">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
                  }}
                >
                  -
                </span>
                <span>{quantity}</span>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setQuantity((prev) => prev + 1);
                  }}
                >
                  +
                </span>
              </div>
              <div
                className="bg-[#B99545] hover:bg-[#e0b454]  text-center text-white w-full p-2 cursor-pointer active:bg-[#9a7d37]"
                onClick={handleAddToCart}
              >
                Add To Cart
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PerfumeCard2 = ({ perfume: initialPerfume }) => {
  const navigate = useNavigate();
  const [showQuickView, setShowQuickView] = useState(false);
  const openQuickView = () => setShowQuickView(true);
  const closeQuickView = () => setShowQuickView(false);
  const navigateToAddToCart = () => {
    navigate("/addToCart", { state: { perfume: initialPerfume } });
    window.scrollTo(0, 0);
  };
  const [perfume, setPerfume] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProduct = async () => {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      try {
        // Dispatch the action and wait for completion
        const response = await axios.get(
          `${API_BASE_URL}/products/${initialPerfume.name}`
        );

        setPerfume(response.data.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProduct();
  }, [dispatch, initialPerfume.name]);
  const { loading, productByName } = useSelector(
    (store) => store.productsReducer
  );

  useEffect(() => {
    if (productByName?.data) {
      setPerfume(productByName.data);
    }
  }, [productByName]);

  let modifiedStyles = {
    ...customStyles,
    content: {
      ...customStyles.content,
      margin: "auto",
      width: window.innerWidth > 420 ? "70%" : "",
      height: window.innerHeight > 660 ? "60%" : "",
      overflow: "scroll",
      backgroundColor: "#454545",
      scrollbarWidth: "none",
    },
  };
  return (
    <>
      <Modal
        isOpen={showQuickView}
        onRequestClose={closeQuickView}
        style={modifiedStyles}
      >
        <QuickView perfume={initialPerfume} onClose={closeQuickView} />
      </Modal>

      {loading || !perfume ? (
        <div class="skeleton-card">
          <div class="skeleton-img"></div>
          <div class="skeleton-text"></div>
          <div class="skeleton-text short"></div>
        </div>
      ) : (
        <motion.div
          onClick={navigateToAddToCart}
          className="flex flex-col justify-between items-stretch gap-3 h-full cursor-pointer relative"
          whileHover={{
            scale: 1.05,
          }}
        >
          <div className="bg-[#454545] h-[35vh] flex items-center justify-center relative">
            <div className="flex  items-center justify-center w-full h-full ">
              <Img
                src={process.env.REACT_APP_BASE_URL + initialPerfume?.image}
                alt=""
                className="image-fixed-size"
                loader={<div class="skeleton-img h-full"></div>}
              />
            </div>

            <motion.div className="absolute inset-0 flex flex-col items-center justify-center gap-4 opacity-0 hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
              <motion.div
                className="flex items-center justify-center bg-black text-white p-2 px-4 rounded-full border border-white cursor-pointer"
                whileHover={{ scale: 1.1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToAddToCart();
                }}
              >
                <motion.span
                  className="text-sm"
                  whileHover={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  Add to cart
                </motion.span>
              </motion.div>

              <motion.div
                className="flex items-center justify-center text-black bg-white p-2 px-4 rounded-full border border-white cursor-pointer"
                whileHover={{ scale: 1.1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  openQuickView();
                }}
              >
                <motion.span
                  className="text-sm"
                  whileHover={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  Quick View
                </motion.span>
              </motion.div>
            </motion.div>
          </div>

          <div className="flex-grow flex justify-between flex-col gap-2 text-white">
            <h1 className="text-lg h-auto opacity-80">{perfume[0]?.name}</h1>
            <h3 className="opacity-80 text-xl">
              ${perfume[0]?.price} ~ ${perfume[1]?.price}
            </h3>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default PerfumeCard2;
