import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,

  loadingPBN: false,
  errorPBN: false,
  products: [],
  menProducts: [],
  womenProducts: [],
  productByName: {},
};

const productsPageSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getProductsRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.products = [];
    },
    getProductsSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.products = action.payload;
    },
    getProductsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.products = [];
    },

    getMenProductsRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.menProducts = [];
    },
    getMenProductsSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.menProducts = action.payload;
    },
    getMenProductsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.menProducts = [];
    },

    getWomenProductsRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.womenProducts = [];
    },
    getWomenProductsSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.womenProducts = action.payload;
    },
    getWomenProductsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.womenProducts = [];
    },

    getProductByNameRequest: (state, action) => {
      state.loadingPBN = true;
      state.errorPBN = false;
      state.productByName = {};
    },
    getProductByNameSuccess: (state, action) => {
      state.loadingPBN = false;
      state.errorPBN = false;
      state.productByName = action.payload;
    },
    getProductByNameFailure: (state, action) => {
      state.loadingPBN = false;
      state.errorPBN = action.payload;
      state.productByName = {};
    },
  },
});

export const ProductsPageAction = productsPageSlice.actions;
export default productsPageSlice;
