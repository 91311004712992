import React, { useEffect, useState } from "react";
import Recommended from "../../Assets/Men/recommended.webp";
import InitialBg from "../../Assets/Men/initialBg.png";
import SpecialCard from "../../Components/specialsCard";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Redux/Products/ProductsActions";
import { getMenData } from "../../Redux/ForHim/ForHimActions";
import Footer1 from "../../Components/Footer1/footer1";
import PerfumeCard2 from "../../Components/PerfumeCard/perfumeCard2";
import Loader from "../../Components/Loader/loader";

const ForHim = () => {
  const [perfumes, setPerfumes] = useState([]);
  const dispatch = useDispatch();
  const { loading, menData } = useSelector((store) => store.forHimReducer);
  const { products } = useSelector((store) => store.productsReducer);

  const [homeSection, setHomeSection] = useState([]);
  const [recommendedPerfumes, setRecommendedPerfumes] = useState([]);
  const [specialPerfumes, setSpecialPerfumes] = useState([]);
  const [specialBackgrounds, setSpecialBackgrounds] = useState([]);

  const special_perfumes_data = [
    {
      brand: "Tom Ford",
      tagline: "Redefining Luxury with Every Scent",
      name: "Noir de Noir",
      description:
        "A sensual blend of black rose, truffle, and vanilla, layered with patchouli and oud wood, creating a dark, mysterious, and opulent signature scent.",
    },
    {
      brand: "Prada",
      tagline: "Unveil the Mystery of Sophistication",
      name: "Prada Black",
      description:
        "A bold, captivating fragrance that combines the richness of amber and patchouli with the smoothness of vanilla and leather, creating a sophisticated and enigmatic scent that lingers with intensity.",
    },
    {
      brand: "Dior",
      tagline: "A Bold Expression of Masculinity",
      name: "Dior Homme Intense",
      description:
        "A sophisticated and powerful fragrance that fuses leather, iris, and amber, with hints of vetiver and oud. This intense blend captures the essence of confidence, elegance, and strength, creating a lasting impression.",
    },
  ];

  useEffect(() => {
    dispatch(getMenData());
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (menData.data) {
      setHomeSection(
        menData.data.filter((section) => section.section === "home")
      );
      setRecommendedPerfumes(
        menData.data.filter((section) => section.section === "recommended")
      );
      setSpecialBackgrounds(
        menData.data.filter(
          (section) => section.section === "special_backgrounds"
        )
      );
      setSpecialPerfumes(
        menData.data.filter((section) => section.section === "special_perfumes")
      );
    }

    if (products.data) {
      const filteredPerfumes = products?.data?.filter(
        (perfume, index, self) =>
          perfume.category === "Male" &&
          perfume.subscriptionCategory === "None" &&
          self.findIndex((t) => t.name === perfume.name) === index
      );
      const randomPerfumes = filteredPerfumes
        .sort(() => Math.random() - 0.5)
        .slice(0, 8);
      setPerfumes(randomPerfumes);
    }
  }, [menData, products]);

  useEffect(() => {
    console.log(menData);
  }, [menData]);

  return (
    <div className="bg-[#313131]">
      {!loading && (
        <div className="w-full bg-[#313131] pb-10">
          {/* Heading Image with Text */}
          <div
            className="w-full h-screen bg-cover bg-right flex items-start justify-center"
            style={{
              backgroundImage: `url(${
                homeSection[0]?.content
                  ? process.env.REACT_APP_BASE_URL + homeSection[0]?.content
                  : InitialBg
              })`,
              backgroundSize: "cover",
              backgroundPosition: "75% 50%",
            }}
          >
            {/* Text */}
            <div className="w-full h-full px-2 lg:px-0 lg:w-4/5 mx-auto pt-5 flex flex-col justify-start md:justify-center gap-2">
              <h1 className="text-white text-sm font-thin">Men's Colognes</h1>
              <h2 className="text-white text-4xl md:text-6xl font-thin w-full lg:w-2/3 ">
                Discover the Essence <br /> of Masculinity
              </h2>
              <p className="w-1/3 hidden md:block text-white font-thin opacity-80 text-sm">
                Elevate your presence with our curated selection of premium
                fragrances. Each scent embodies strength, confidence, and
                sophistication, designed to leave a lasting impression. Explore
                timeless classics and modern blends crafted to complement every
                moment and mood. Step into a world where fragrance becomes your
                signature.
              </p>
              <div className="flex flex-row gap-2 mt-5">
                <Link to={"/products"} state={{ gender: "Male" }}>
                  <div className="bg-white text-black p-2 px-3 md:p-3 md:px-6 font-thin text-sm cursor-pointer">
                    Explore our collection
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Highly Recommended Image + Title */}
          <div className="py-9">
            <div className="w-full text-center">
              <h1 className="text-white text-3xl md:text-5xl">
                Highly recommended
              </h1>
            </div>

            <div className="w-full md:w-4/5 mx-auto mt-10 relative">
              <img src={Recommended} alt="" className="w-full h-full" />

              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  recommendedPerfumes[0]?.content
                }
                alt="Perfume"
                class="perfume"
              />

              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  recommendedPerfumes[1]?.content
                }
                alt="Perfume"
                class="perfume_2"
              />
            </div>
          </div>

          {/* Specials */}
          <div className="py-9">
            <div className="w-full text-center">
              <h1 className="text-white text-3xl md:text-5xl">Specials</h1>
            </div>

            <div className="w-4/5 m-auto mt-10 grid grid-cols-1 gap-2 custom-grid ">
              {specialPerfumes.map((element, index) => (
                <div key={index} className="w-full flex">
                  <SpecialCard
                    index={index + 1}
                    perfume={element}
                    backgroundImage={specialBackgrounds[index]?.content}
                    special_perfumes_data={special_perfumes_data[index]}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product List */}
          <div className="py-9">
            <div className="w-full text-center text-white">
              <h1 className=" text-3xl md:text-5xl">Product List</h1>
            </div>
          </div>

          <div className="w-11/12 mx-auto py-10">
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-4  gap-4 gap-y-10">
              {perfumes.map((element, index) => (
                <PerfumeCard2 perfume={element} key={index} />
              ))}
            </div>
          </div>

          <div className="w-full flex flex-row items-center justify-center mt-10">
            <Link to={"/products"} state={{ gender: "Male" }}>
              <div className="p-2 bg-white text-black cursor-pointer">
                Explore our collection
              </div>
            </Link>
          </div>
        </div>
      )}

      {loading && (
        <div className="w-full h-screen flex items-center justify-center">
          <Loader />
        </div>
      )}

      <Footer1 />
    </div>
  );
};

export default ForHim;
