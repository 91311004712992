import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductByName,
  getProducts,
} from "../../Redux/Products/ProductsActions";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart } from "../../Redux/Cart/CartActions";
import { toast } from "react-toastify";
import { isTokenValid } from "../../utils/verifyToken";
import Magnifier from "react-magnifier";

import Loader from "../../Components/Loader/loader";
import PerfumeCard2 from "../../Components/PerfumeCard/perfumeCard2";
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const AddToCart = () => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const { perfume: initialPerfume } = location.state;
  const [perfume, setPerfume] = useState(initialPerfume);
  const [sizeChosen, setSizeChosen] = useState("5ml");
  const dispatch = useDispatch();
  const { products, productByName } = useSelector(
    (store) => store.productsReducer
  );
  const [perfumes, setPerfumes] = useState([]);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getProductByName(initialPerfume.name));
  }, [dispatch, initialPerfume]);

  useEffect(() => {
    if (productByName?.data) {
      setPerfume(
        productByName.data.filter((p) => p.perfume_size === sizeChosen)
      );
    }
  }, [productByName, sizeChosen]);

  const handleAddToCart = () => {
    if (!isTokenValid()) {
      navigate("/auth");
      return;
    }
    dispatch(addToCart(perfume[0], quantity, sizeChosen));
    toast.success("Perfume added to cart");
  };

  useEffect(() => {
    if (products.data && products.data.length > 0) {
      const filteredProducts = products.data.filter(
        (product) =>
          product.category === initialPerfume.category &&
          product.name !== initialPerfume.name &&
          product.subscriptionCategory === "None"
      );

      const similarNamePerfumes = filteredProducts.filter(
        (product, index, self) => {
          const productName = product.name.toLowerCase();
          const initialName = initialPerfume.name.toLowerCase();

          const brandName =
            initialName.split(" ")[0] + " " + initialName.split(" ")[1];

          const isSimilar =
            productName.startsWith(brandName) &&
            index === self.findIndex((p) => p.name === product.name);

          return isSimilar;
        }
      );

      const uniqueProducts = filteredProducts.filter(
        (product, index, self) =>
          index === self.findIndex((p) => p.name === product.name)
      );

      const shuffledSimilarPerfumes = shuffleArray(similarNamePerfumes);

      const remainingCount = 4 - shuffledSimilarPerfumes.length;
      const remainingRandomPerfumes = shuffleArray(
        uniqueProducts.filter(
          (product) =>
            !shuffledSimilarPerfumes.some(
              (selected) => selected.name === product.name
            )
        )
      ).slice(0, remainingCount);

      const finalPerfumes = [
        ...shuffledSimilarPerfumes,
        ...remainingRandomPerfumes,
      ];

      setPerfumes(finalPerfumes.slice(0, 4));
    }
  }, [products, initialPerfume.category, initialPerfume.name]);

  return (
    <div className="w-full bg-[#313131] py-10 ">
      <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-3">
        <div className="h-96 flex-1 border p-6 flex justify-center items-center relative">
          <Magnifier
            src={process.env.REACT_APP_BASE_URL + initialPerfume?.image}
            alt="Perfume"
            width={200}
            className="h-full"
            loader={<Loader />}
          />
        </div>

        {/* SECOND COLUMN */}
        <div className="flex-1 flex flex-col gap-2 text-white">
          <h1>Perfumster Fragrance</h1>
          <h1 className="text-lg font-bold  md:text-2xl">
            {initialPerfume?.name}
          </h1>
          <h1
            className={` ${
              perfume[0]?.status !== "Available"
                ? "text-2xl bg-red-600 md:w-1/2 w-2/3 text-center"
                : "text-2xl md:text-4xl"
            }`}
          >
            {" "}
            {perfume[0]?.status === "Available"
              ? "$" + perfume[0]?.price
              : "Out of stock"}{" "}
          </h1>
          <p className="text-sm my-6">{initialPerfume?.description}</p>

          {/* <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div>

          <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div>

          <div className="">
            <h1 className="font-bold">Top Notes :</h1>
            <h1 className="opacity-60">Test Test Test</h1>
          </div> */}

          <hr />

          <div className="flex flex-row gap-2 items-center">
            <h1 className="text-xl">Size : </h1>
            <div className="flex flex-row gap-2 items-center">
              {productByName.data &&
                productByName.data[0].perfume_size === "5ml"}
              <div
                onClick={() => setSizeChosen("5ml")}
                className={`border px-6 py-2 cursor-pointer ${
                  sizeChosen === "5ml" && "bg-[#B99545]"
                }`}
              >
                <span>5ml</span>
              </div>

              <div
                onClick={() => setSizeChosen("10ml")}
                className={`border px-6 py-2 cursor-pointer ${
                  sizeChosen === "10ml" && "bg-[#B99545]"
                }`}
              >
                <span>10ml</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <span>QTY:</span>
            <div className="flex flex-row gap-2 p-1 px-2 w-28 border justify-between">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
                }}
              >
                -
              </span>
              <span>{quantity}</span>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQuantity((prev) => prev + 1);
                }}
              >
                +
              </span>
            </div>
            <button
              disabled={perfume[0]?.status !== "Available"}
              className={`bg-[#B99545] text-center text-white w-full p-2  ${
                perfume[0]?.status !== "Available"
                  ? "cursor-not-allowed "
                  : "cursor-pointer hover:bg-[#997c3c] "
              }`}
              onClick={handleAddToCart}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
      <div className="w-full text-center mt-6 text-white text-4xl">
        <span>Similar Products</span>
      </div>
      <div className="w-4/5 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-10">
        {perfumes.map((element, index) => (
          <PerfumeCard2 perfume={element} key={index} />
        ))}
      </div>
    </div>
  );
};

export default AddToCart;
