import { ProductsPageAction } from "./ProductsReducers";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getProducts =
  (currentPage, productsPerPage, setTotalPages) => async (dispatch) => {
    try {
      dispatch(ProductsPageAction.getProductsRequest());
      const response = await axios.get(
        `${API_BASE_URL}/products?page=${currentPage}&limit=${productsPerPage}`
      );

      if (setTotalPages && typeof setTotalPages === "function") {
        setTotalPages(response.data.totalPages);
      }
      dispatch(ProductsPageAction.getProductsSuccess(response.data));
    } catch (e) {
      dispatch(ProductsPageAction.getProductsFailure(e.message));
    }
  };

export const getMenProducts =
  (currentPage, productsPerPage, setTotalPages) => async (dispatch) => {
    try {
      dispatch(ProductsPageAction.getMenProductsRequest());
      const response =
        await axios.get(`${API_BASE_URL}/products?page=${currentPage} 
    &limit=${productsPerPage}
      `);
      if (setTotalPages && typeof setTotalPages === "function") {
        setTotalPages(response.data.totalPages);
      }
      dispatch(ProductsPageAction.getMenProductsSuccess(response.data));
    } catch (e) {
      dispatch(ProductsPageAction.getMenProductsFailure(e.message));
    }
  };

export const getWomenProducts =
  (currentPage, productsPerPage, setTotalPages) => async (dispatch) => {
    try {
      dispatch(ProductsPageAction.getWomenProductsRequest());
      const response =
        await axios.get(`${API_BASE_URL}/products/women?page=${currentPage} 
&limit=${productsPerPage}
  `);
      if (setTotalPages && typeof setTotalPages === "function") {
        setTotalPages(response.data.totalPages);
      }
      dispatch(ProductsPageAction.getWomenProductsSuccess(response.data));
    } catch (e) {
      dispatch(ProductsPageAction.getWomenProductsFailure(e.message));
    }
  };

export const getProductByName = (name, setPerfume) => async (dispatch) => {
  try {
    dispatch(ProductsPageAction.getProductByNameRequest());
    const response = await axios.get(`${API_BASE_URL}/products/${name}`);
    if (setPerfume && typeof setPerfume === "function") {
      setPerfume(response.data);
      return;
    }
    dispatch(ProductsPageAction.getProductByNameSuccess(response.data));
  } catch (e) {
    dispatch(ProductsPageAction.getProductByNameFailure(e.message));
  }
};
